<template>
  <div class="noscrollmain">
    <common-top
      title="部门活跃度"
      :isShowSearch="isShowSearch"
      @search="showModal = true"
    ></common-top>

    <Modal v-model="showDetailStatus" fullscreen>
      <p slot="header" style="text-align: center">
        <span>{{ detailInfo["deptName"] }}</span>
      </p>
      <div class="commonShadow">
        <div>
          <div class="deptMainTop">
            <div class="box-item commonBorder">
              <span>
                <span>
                  <svg
                    t="1647753036303"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    p-id="3029"
                    width="32"
                    height="32"
                  >
                    <path
                      d="M430.602 443.728H172.941v409.721h257.661l9.723 22.763h123.159l12.965-22.763h251.179V437.225z"
                      fill="#ED8F27"
                      p-id="3030"
                    ></path>
                    <path
                      d="M563.484 888.712H440.325l-11.495-7.59-6.48-15.173H172.94l-12.5-12.5v-409.72l12.5-12.5H430.5l396.923-6.502 12.705 12.498V853.45l-12.5 12.5H583.714l-9.367 16.449-10.863 6.313z m-114.906-25h107.641l9.367-16.449 10.862-6.313h238.68V449.931l-384.525 6.298H185.44V840.95h245.162l11.495 7.59 6.481 15.172z"
                      fill="#300604"
                      p-id="3031"
                    ></path>
                    <path
                      d="M772.531 386.823s-221.458-42.273-266.298 50.402l-22.982 384.568h52.685l4.862-31.937h231.733V386.823z"
                      fill="#FCE3C3"
                      p-id="3032"
                    ></path>
                    <path
                      d="M535.936 831.793h-52.685l-9.982-10.597 22.981-384.568 0.98-3.759c11.444-23.653 33.769-41.182 66.351-52.099 24.92-8.351 56.024-12.892 92.449-13.499 61.296-1.015 116.074 9.29 118.376 9.729l8.125 9.822v403.034l-10 10H549.39l-3.568 23.441-9.886 8.496z m-42.07-20h33.477l3.568-23.441 9.886-8.495h221.734V395.259c-17.44-2.796-60.6-8.781-106.461-7.984-93.223 1.611-127.655 29.096-139.979 52.604l-22.225 371.914z"
                      fill="#300604"
                      p-id="3033"
                    ></path>
                    <path
                      d="M224.797 386.823H469.99v403.034H224.797z"
                      fill="#FCE3C3"
                      p-id="3034"
                    ></path>
                    <path
                      d="M469.99 799.856H224.797l-10-10V386.822l10-10H469.99l10 10v403.034l-10 10z m-235.193-20H459.99V396.822H234.797v383.034z"
                      fill="#300604"
                      p-id="3035"
                    ></path>
                    <path
                      d="M292.542 310.406s139.34 22.474 190.709 81.026c27.337 31.16 26.78 73.506 26.78 73.506v317.628c0 12.645-6.106 24.506-16.384 31.825l-10.396 7.403s0.118-26.71-27.886-44.677-162.823-66.744-162.823-66.744V310.406z"
                      fill="#FCE3C3"
                      p-id="3036"
                    ></path>
                    <path
                      d="M489.052 829.938l-15.801-8.189c-0.013-0.789-0.575-21.645-23.286-36.216-21.401-13.731-114.557-49.018-160.825-65.758l-6.598-9.403V310.406l11.593-9.872c5.834 0.94 143.502 23.743 196.634 84.304 29.188 33.269 29.287 77.311 29.262 80.148v317.579c0 15.851-7.694 30.793-20.583 39.971l-10.396 7.402z m-186.51-126.567c27.82 10.177 132.917 49.092 158.224 65.328 17.225 11.052 25.318 25.077 29.12 35.95a29.13 29.13 0 0 0 10.145-22.084l0.001-317.76c0.003-0.365 0.059-39.017-24.297-66.778-40.047-45.646-139.256-68.81-173.192-75.589v380.933zM610.066 444.946h123.76v27.902h-123.76zM610.066 510.959h123.76v27.902h-123.76zM665.936 689.082h62.532v27.902h-62.532zM633.087 194.47l16.4 11.448-80.858 115.834-16.4-11.448zM725.26 204.73l13.734 14.54L634.785 317.7l-13.733-14.539zM800.489 240.542l13.142 15.076-52.696 45.935-13.142-15.076zM468.638 187.397l27.52 56.292-17.968 8.784-27.52-56.292zM331.501 204.24l120.28 98.428-12.666 15.478-120.28-98.427zM243.412 238.813l42.402 17.49-7.626 18.488-42.402-17.49z"
                      fill="#300604"
                      p-id="3037"
                    ></path>
                  </svg>
                </span>
              </span>

              <span class="descColor"> 单位阅读 </span>
              <span class="numColor">
                {{ detailInfo["count"] }}
              </span>
            </div>

            <div class="box-item commonBorder">
              <span>
                <span>
                  <svg
                    t="1647759201115"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    p-id="30404"
                    width="32"
                    height="32"
                  >
                    <path
                      d="M180.2 363.5h133.1c15.6 0 30.8-4.9 43.5-14l29.1-20.9c66.1-47.5 106.9-122.5 110.9-203.8C497.9 102 516.7 84 539.6 84h4.8c20.7 0 35.2 7 51 28.1 48.4 60.4 50.3 140.4 35.9 214.4l-2.3 12c-4.9 11.9 3.9 24.9 16.7 24.9H828c45.1 0 78.4 8.1 67.2 86.2C873.5 639 818.3 815.7 797 857.4c-12.8 23.7-31.6 39.3-56.6 39.3H209.7c-28.1 0-51.3-21.7-53.3-49.7L127 420.4c-2.2-30.7 22.2-56.9 53.2-56.9z"
                      fill="#FFA820"
                      p-id="30405"
                    ></path>
                    <path
                      d="M630.8 896.9h109.5c25 0 43.8-15.7 56.6-39.3 21.2-41.8 76.5-218.5 98.2-407.8 10.3-71.3-16.6-84.2-55.7-85.9 21.5 49.5 33.4 104.1 33.4 161.6-0.1 165.7-99.5 308.3-242 371.4zM151.8 781.8l4.5 65.4c1.9 28 25.2 49.7 53.3 49.7h93c-59-26.1-110.6-65.8-150.8-115.1zM497.2 120.4c42.4 3.2 83 12.8 120.7 28-6-12.6-13.5-24.8-22.7-36.2-7.8-10.4-15.3-17.4-23.4-21.8-12.8-2.5-25.9-4.4-39-5.8-18.2 2.9-32.8 17.3-35.6 35.8z"
                      fill="#FEB133"
                      p-id="30406"
                    ></path>
                    <path
                      d="M449.1 856.1c-130.5 0-244.2-71.4-304.4-177.3l7.1 103c40.2 49.3 91.8 89 150.7 115.1h328.3c142.5-63 242-205.7 242-371.5 0-57.4-11.9-112-33.4-161.6-3.7-0.2-7.6-0.2-11.5-0.2h-59.2c19.5 43.6 30.3 91.9 30.3 142.7-0.1 193.1-156.7 349.8-349.9 349.8zM496.7 124.8c-0.6 11.6-1.9 23-3.9 34.2 52.3 6.5 101 24.6 143.4 51.5-3.1-21.6-8.9-42.6-18.3-62.3-37.8-15.2-78.3-24.8-120.7-28-0.2 1.6-0.4 3.1-0.5 4.6z"
                      fill="#FEB533"
                      p-id="30407"
                    ></path>
                    <path
                      d="M482.8 198.1c60 10.6 113.6 39.4 155.1 80.4 1.8-22.8 1.6-45.7-1.6-67.9-42.5-27-91.2-45-143.4-51.5-2.5 13.3-5.8 26.3-10.1 39zM431.4 780.6c-162.1 0-293.5-131.4-293.5-293.5 0-43.2 9.3-84.2 26.1-121.1-22.8 7.2-38.8 29.3-37.1 54.5l17.8 258.2C204.8 784.6 318.6 856 449.1 856c193.2 0 349.8-156.6 349.8-349.8 0-50.8-10.8-99.1-30.3-142.7h-70.9c17.5 37.6 27.2 79.4 27.2 123.6 0 162.1-131.4 293.5-293.5 293.5z"
                      fill="#FEBA33"
                      p-id="30408"
                    ></path>
                    <path
                      d="M466.1 236.6C572 260.5 651 355 651 468.1c0 131-106.2 237.3-237.3 237.3S176.5 599.1 176.5 468.1c0-37.5 8.7-73 24.2-104.5h-20.6c-5.6 0-11.1 0.9-16.2 2.5-16.8 36.9-26.1 77.9-26.1 121.1 0 162.1 131.4 293.5 293.5 293.5s293.5-131.4 293.5-293.5c0-44.2-9.8-86-27.2-123.6h-52.1c-12.9 0-21.6-13-16.7-24.9l2.3-12c3.1-15.8 5.4-31.9 6.7-48.1-41.5-41.1-95.2-69.8-155.1-80.4-4.5 13.2-10 26-16.6 38.4z"
                      fill="#FFBE34"
                      p-id="30409"
                    ></path>
                    <path
                      d="M413.7 705.3c131 0 237.3-106.2 237.3-237.3 0-113-79.1-207.6-184.9-231.4-6.9 13.1-14.9 25.6-23.9 37.4 77.6 20.4 134.9 91 134.9 175 0 99.9-81 180.9-180.9 180.9s-180.9-81-180.9-180.9c0-30.9 7.8-60 21.4-85.4h-36c-15.5 31.5-24.2 67-24.2 104.5 0 131 106.2 237.2 237.2 237.2z"
                      fill="#FFC234"
                      p-id="30410"
                    ></path>
                    <path
                      d="M396.2 629.9c99.9 0 180.9-81 180.9-180.9 0-84-57.3-154.6-134.9-175-9.6 12.6-20.4 24.4-32.3 35.2 53.7 13.9 93.3 62.7 93.3 120.7 0 68.9-55.8 124.7-124.7 124.7s-124.7-55.8-124.7-124.7c0-24.4 7-47.1 19.1-66.4h-36.3C223 389 215.3 418.1 215.3 449c0 99.9 81 180.9 180.9 180.9z"
                      fill="#FFC734"
                      p-id="30411"
                    ></path>
                    <path
                      d="M378.5 554.5c68.9 0 124.7-55.8 124.7-124.7 0-58-39.6-106.8-93.3-120.7-7.6 6.9-15.6 13.4-24 19.5l-29.1 20.9c-12.7 9.1-27.9 14-43.5 14H273c-12.1 19.2-19.1 42-19.1 66.4-0.1 68.8 55.8 124.6 124.6 124.6z"
                      fill="#FFCB34"
                      p-id="30412"
                    ></path>
                    <path
                      d="M740.3 905.9H209.6c-32.7 0-60-25.5-62.2-58.1L118 421.2c-1.2-17.2 4.9-34.3 16.6-46.8 11.8-12.6 28.4-19.8 45.6-19.8h133.1c13.8 0 27.1-4.3 38.3-12.3l29.1-20.9c31.3-22.5 57.7-52.3 76.1-86.2 18.4-33.9 29.2-72.2 31-110.7 1.3-27.7 24.1-49.4 51.8-49.4h4.8c24.4 0 41.3 9.2 58.1 31.6 43.1 53.9 55.8 128.5 37.6 221.7l-2.5 12.8-0.3 0.8c-1.2 2.8-0.9 5.9 0.9 8.5 1.7 2.6 4.5 4 7.5 4H828c22.8 0 48.5 1.5 64.2 19.7 13.4 15.5 17.2 39.8 11.9 76.6-12.1 105.5-33.6 199.8-49.5 260.4-19.8 75.4-39.2 129.9-49.6 150.4l-0.1 0.2c-15.7 28.8-38 44.1-64.6 44.1zM180.2 372.5c-12.4 0-24 5-32.4 14.1s-12.7 20.9-11.8 33.3l29.4 426.6c1.6 23.2 21 41.3 44.3 41.3h530.7c19.9 0 36.2-11.6 48.6-34.5 10-19.7 28.8-72.7 48.2-146.7 15.8-60 37.1-153.5 49-258v-0.3c4.5-30.9 1.9-51.3-7.7-62.4-9.7-11.2-27.2-13.5-50.6-13.5H645.5c-9 0-17.4-4.5-22.5-12-4.9-7.2-5.9-16.3-2.9-24.4l2.2-11.2c17.1-87.9 5.6-157.5-34.1-207.1l-0.2-0.2C574.5 99.4 563 93 544.2 93h-4.8c-18.1 0-32.9 14.2-33.8 32.2-2 41.2-13.5 82.2-33.2 118.5-19.7 36.3-47.9 68.2-81.4 92.2l-29 20.9c-14.3 10.3-31.2 15.7-48.8 15.7h-133z"
                      fill="#FFA820"
                      p-id="30413"
                    ></path>
                    <path
                      d="M536.9 198.5c-9.7-2.4-19.4 3.5-21.8 13.2-15.5 63-62.2 107.6-62.8 108.1-7.2 6.8-7.6 18.2-0.8 25.4 3.5 3.8 8.3 5.7 13.1 5.7 4.4 0 8.8-1.6 12.3-4.9 2.2-2.1 55-52.1 73.1-125.8 2.5-9.6-3.4-19.3-13.1-21.7zM411.9 354.5l-9.5 6.8c-8.1 5.8-9.9 17.1-4.1 25.1 3.2 4.5 8.1 7 13.2 7.4 4.1 0.3 8.3-0.8 11.9-3.4l9.5-6.8c8.1-5.8 9.9-17.1 4.1-25.1-5.8-8-17-9.8-25.1-4z"
                      fill="#FFFFFF"
                      p-id="30414"
                    ></path>
                    <path
                      d="M222 426.3h78.3c13.4 0 24.3 10.9 24.3 24.3v359.6c0 13.4-10.9 24.3-24.3 24.3h-54.5c-12.8 0-23.4-9.9-24.2-22.7l-23.8-359.6c-0.9-14 10.2-25.9 24.2-25.9z"
                      fill="#FFE3B4"
                      p-id="30415"
                    ></path>
                  </svg>
                </span>
              </span>
              <span class="descColor"> 点赞 </span>

              <span class="numColor">
                {{ detailInfo["like"] }}
              </span>
            </div>

            <div class="box-item commonBorder">
              <span>
                <svg
                  t="1648031415437"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  p-id="5560"
                  width="32"
                  height="32"
                >
                  <path
                    d="M902.4 291.2L692.2 128.7c-44-34-107.9-2.6-107.9 53v45c-188.9 1.3-341.9 159.8-341.9 356 0 42.7 7.1 80.7 19 118.7C308.8 563.7 434.7 464 584.2 464v42.7c0 55.6 63.9 87 107.9 53l210.3-162.5c34.7-26.8 34.7-79.2 0-106z"
                    fill="#2867CE"
                    p-id="5561"
                  ></path>
                  <path
                    d="M768.3 901.9H257.7c-93.1 0-168.5-75.5-168.5-168.5V353.6c0-93.1 75.5-168.5 168.5-168.5h49.6c26.6 0 48.1 21.5 48.1 48.1s-21.5 48.1-48.1 48.1h-49.6c-40 0-72.4 32.4-72.4 72.4v379.8c0 40 32.4 72.4 72.4 72.4h510.5c40 0 72.4-32.4 72.4-72.4v-132c0-26.6 21.5-48.1 48.1-48.1s48.1 21.5 48.1 48.1v132c0 93-75.5 168.4-168.5 168.4z"
                    fill="#BDD2EF"
                    p-id="5562"
                  ></path>
                </svg>
              </span>

              <span class="descColor"> 分享 </span>

              <span class="numColor">
                {{ detailInfo["share"] }}
              </span>

              <span> </span>
            </div>
            <div class="box-item commonBorder">
              <span>
                <svg
                  t="1648029984898"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  p-id="16410"
                  width="32"
                  height="32"
                >
                  <path
                    d="M237.056 144.1792h314.9312c74.8544 0 135.5264 60.672 135.5264 135.5264v220.7744c0 74.8544-60.672 135.5264-135.5264 135.5264H323.3792l-117.504 96.3584c-12.032 9.8816-30.1568 1.3312-30.1568-14.2848v-96.768c-44.032-22.3744-74.24-68.096-74.24-120.8832V279.7056c0-74.8544 60.7232-135.5264 135.5776-135.5264z"
                    fill="#EF8F9F"
                    p-id="16411"
                  ></path>
                  <path
                    d="M516.7104 151.8592s80.2816 35.072 80.2816 85.9648c51.456 0 92.16-4.5056 96.6656 0 4.5568 4.4544-35.0208-99.584-176.9472-85.9648z"
                    fill="#E06C85"
                    p-id="16412"
                  ></path>
                  <path
                    d="M194.304 757.1456c-5.632 0-11.3664-1.2288-16.7424-3.7888a38.6048 38.6048 0 0 1-22.272-35.1744V633.344c-46.08-28.3136-74.24-78.1312-74.24-132.864V279.7056c0-86.016 69.9904-156.0064 156.0064-156.0064h314.9312c86.016 0 156.0064 69.9904 156.0064 156.0064v220.7744c0 86.016-69.9904 156.0064-156.0064 156.0064H330.7008l-111.8208 91.7504a38.62016 38.62016 0 0 1-24.576 8.9088zM237.056 164.6592c-63.4368 0-115.0464 51.6096-115.0464 115.0464v220.7744c0 43.52 24.1664 82.8928 63.0272 102.6048 6.8608 3.4816 11.2128 10.5472 11.2128 18.2784v92.4672l114.176-93.6448c3.6864-3.0208 8.2432-4.6592 13.0048-4.6592h228.608c63.4368 0 115.0464-51.6096 115.0464-115.0464V279.7056c0-63.4368-51.6096-115.0464-115.0464-115.0464H237.056z"
                    fill="#211D38"
                    p-id="16413"
                  ></path>
                  <path
                    d="M780.3904 240.8448H440.2688c-80.8448 0-146.3808 65.536-146.3808 146.3808v238.3872c0 80.8448 65.536 146.3808 146.3808 146.3808h246.8864l126.8736 104.0896c13.0048 10.6496 32.5632 1.4336 32.5632-15.4112v-104.4992c47.5648-24.1664 80.1792-73.5232 80.1792-130.56V387.2256c0-80.8448-65.536-146.3808-146.3808-146.3808z"
                    fill="#F3556E"
                    p-id="16414"
                  ></path>
                  <path
                    d="M763.8016 253.0304s101.7856 35.072 101.7856 130.048v200.1408c0 52.48-8.3968 106.752-61.3376 123.6992-12.4928 3.9936-21.1968 15.3088-21.1968 28.416v37.888l-70.3488-54.784a29.7472 29.7472 0 0 0-18.2272-6.2464H442.3168c-44.0832 0-125.9008-16.5888-139.1104-79.5136 0.768 39.936 23.7568 143.616 144.384 143.616h253.2864l124.7744 104.8064 14.336-2.6624 14.336-122.5216s73.1136-60.672 73.1136-138.7008V355.9936c0-33.1776-44.1344-119.1424-163.6352-102.9632z"
                    fill="#D6283F"
                    p-id="16415"
                  ></path>
                  <path
                    d="M826.5728 901.12c-9.1136 0-18.1248-3.1232-25.5488-9.216l-121.2416-99.4304H440.2688c-92.0064 0-166.8608-74.8544-166.8608-166.8608V387.2256c0-92.0064 74.8544-166.8608 166.8608-166.8608h340.1216c92.0064 0 166.8608 74.8544 166.8608 166.8608v238.4384c0 58.8288-30.4128 112.384-80.1792 142.592v92.4672c0 15.7696-8.8576 29.7472-23.1424 36.5056-5.5296 2.6112-11.4688 3.8912-17.3568 3.8912zM440.2688 261.3248c-69.4272 0-125.9008 56.4736-125.9008 125.9008v238.4384c0 69.4272 56.4736 125.9008 125.9008 125.9008h246.8864c4.7104 0 9.3184 1.6384 13.0048 4.6592l125.952 103.3216V756.224c0-7.7312 4.352-14.7456 11.2128-18.2784 42.5472-21.6064 68.9664-64.6656 68.9664-112.2816V387.2256c0-69.4272-56.4736-125.9008-125.9008-125.9008H440.2688z"
                    fill="#211D38"
                    p-id="16416"
                  ></path>
                  <path
                    d="M477.952 507.7504m-37.6832 0a37.6832 37.6832 0 1 0 75.3664 0 37.6832 37.6832 0 1 0-75.3664 0Z"
                    fill="#FFFFFF"
                    p-id="16417"
                  ></path>
                  <path
                    d="M609.792 507.7504m-37.6832 0a37.6832 37.6832 0 1 0 75.3664 0 37.6832 37.6832 0 1 0-75.3664 0Z"
                    fill="#FFFFFF"
                    p-id="16418"
                  ></path>
                  <path
                    d="M743.8336 507.7504m-37.6832 0a37.6832 37.6832 0 1 0 75.3664 0 37.6832 37.6832 0 1 0-75.3664 0Z"
                    fill="#FFFFFF"
                    p-id="16419"
                  ></path>
                  <path
                    d="M328.0896 583.168a12.8 12.8 0 0 1-12.8-12.8V384c0-56.1152 37.888-104.96 92.16-118.8352 6.8096-1.792 13.824 2.3552 15.5648 9.216 1.7408 6.8608-2.3552 13.824-9.216 15.5648A96.9728 96.9728 0 0 0 340.8896 384v186.3168c0 7.1168-5.7344 12.8512-12.8 12.8512zM481.4848 286.976h-23.3472a12.8 12.8 0 0 1 0-25.6h23.3472a12.8 12.8 0 0 1 0 25.6zM134.5536 481.3824a12.8 12.8 0 0 1-12.8-12.8V284.3648c0-55.5008 37.3248-103.8848 90.7264-117.6064a12.8 12.8 0 0 1 6.3488 24.7808c-42.0864 10.8544-71.4752 48.9984-71.4752 92.8256v184.2176c0 7.0656-5.6832 12.8-12.8 12.8zM285.2864 188.5696h-22.9376a12.8 12.8 0 0 1 0-25.6h22.9376a12.8 12.8 0 0 1 0 25.6z"
                    fill="#FFFFFF"
                    p-id="16420"
                  ></path>
                  <path
                    d="M230.6048 926.8224c-38.5536 0-69.9392-31.3856-69.9392-69.9392s31.3856-69.9392 69.9392-69.9392 69.9392 31.3856 69.9392 69.9392-31.3856 69.9392-69.9392 69.9392z m0-104.0384c-18.7904 0-34.0992 15.3088-34.0992 34.0992s15.3088 34.0992 34.0992 34.0992 34.0992-15.3088 34.0992-34.0992-15.3088-34.0992-34.0992-34.0992z"
                    fill="#E2E5F1"
                    p-id="16421"
                  ></path>
                  <path
                    d="M830.3104 157.696m-34.6624 0a34.6624 34.6624 0 1 0 69.3248 0 34.6624 34.6624 0 1 0-69.3248 0Z"
                    fill="#E2E5F1"
                    p-id="16422"
                  ></path>
                  <path
                    d="M725.2992 892.6208H629.248c-8.4992 0-15.36-6.8608-15.36-15.36s6.8608-15.36 15.36-15.36h96.0512c8.4992 0 15.36 6.8608 15.36 15.36s-6.8608 15.36-15.36 15.36z"
                    fill="#E2E5F1"
                    p-id="16423"
                  ></path>
                  <path
                    d="M579.6352 878.3872m-17.3568 0a17.3568 17.3568 0 1 0 34.7136 0 17.3568 17.3568 0 1 0-34.7136 0Z"
                    fill="#E2E5F1"
                    p-id="16424"
                  ></path>
                </svg>
              </span>
              <span class="descColor"> 评论 </span>
              <span class="numColor">
                {{ detailInfo["comment"] }}
              </span>
            </div>
          </div>
        </div>

        <!-- <Tabs>
          <TabPane name="活跃度趋势" label="活跃度趋势">
            <div>
              <div style="box-shadow: 0 1px 6px #afb1b6">
                <huan-bi-tong-bi-com
                  :optionHuanBiRadio="optionHuanBiRadio"
                  :optionTongBiRadio="optionTongBiRadio"
                  :detailInfo="detailInfo"
                  :showVal="true"
                  ref="huanbiRef"
                ></huan-bi-tong-bi-com>
              </div>
            </div>
          </TabPane>
          <TabPane name="阅读详情" label="阅读详情">
            <div>
              <div class="deptMainTop">
                <div class="box-item commonBorder">
                  <span>
                    <span>
                      <svg
                        t="1647753036303"
                        class="icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        p-id="3029"
                        width="32"
                        height="32"
                      >
                        <path
                          d="M430.602 443.728H172.941v409.721h257.661l9.723 22.763h123.159l12.965-22.763h251.179V437.225z"
                          fill="#ED8F27"
                          p-id="3030"
                        ></path>
                        <path
                          d="M563.484 888.712H440.325l-11.495-7.59-6.48-15.173H172.94l-12.5-12.5v-409.72l12.5-12.5H430.5l396.923-6.502 12.705 12.498V853.45l-12.5 12.5H583.714l-9.367 16.449-10.863 6.313z m-114.906-25h107.641l9.367-16.449 10.862-6.313h238.68V449.931l-384.525 6.298H185.44V840.95h245.162l11.495 7.59 6.481 15.172z"
                          fill="#300604"
                          p-id="3031"
                        ></path>
                        <path
                          d="M772.531 386.823s-221.458-42.273-266.298 50.402l-22.982 384.568h52.685l4.862-31.937h231.733V386.823z"
                          fill="#FCE3C3"
                          p-id="3032"
                        ></path>
                        <path
                          d="M535.936 831.793h-52.685l-9.982-10.597 22.981-384.568 0.98-3.759c11.444-23.653 33.769-41.182 66.351-52.099 24.92-8.351 56.024-12.892 92.449-13.499 61.296-1.015 116.074 9.29 118.376 9.729l8.125 9.822v403.034l-10 10H549.39l-3.568 23.441-9.886 8.496z m-42.07-20h33.477l3.568-23.441 9.886-8.495h221.734V395.259c-17.44-2.796-60.6-8.781-106.461-7.984-93.223 1.611-127.655 29.096-139.979 52.604l-22.225 371.914z"
                          fill="#300604"
                          p-id="3033"
                        ></path>
                        <path
                          d="M224.797 386.823H469.99v403.034H224.797z"
                          fill="#FCE3C3"
                          p-id="3034"
                        ></path>
                        <path
                          d="M469.99 799.856H224.797l-10-10V386.822l10-10H469.99l10 10v403.034l-10 10z m-235.193-20H459.99V396.822H234.797v383.034z"
                          fill="#300604"
                          p-id="3035"
                        ></path>
                        <path
                          d="M292.542 310.406s139.34 22.474 190.709 81.026c27.337 31.16 26.78 73.506 26.78 73.506v317.628c0 12.645-6.106 24.506-16.384 31.825l-10.396 7.403s0.118-26.71-27.886-44.677-162.823-66.744-162.823-66.744V310.406z"
                          fill="#FCE3C3"
                          p-id="3036"
                        ></path>
                        <path
                          d="M489.052 829.938l-15.801-8.189c-0.013-0.789-0.575-21.645-23.286-36.216-21.401-13.731-114.557-49.018-160.825-65.758l-6.598-9.403V310.406l11.593-9.872c5.834 0.94 143.502 23.743 196.634 84.304 29.188 33.269 29.287 77.311 29.262 80.148v317.579c0 15.851-7.694 30.793-20.583 39.971l-10.396 7.402z m-186.51-126.567c27.82 10.177 132.917 49.092 158.224 65.328 17.225 11.052 25.318 25.077 29.12 35.95a29.13 29.13 0 0 0 10.145-22.084l0.001-317.76c0.003-0.365 0.059-39.017-24.297-66.778-40.047-45.646-139.256-68.81-173.192-75.589v380.933zM610.066 444.946h123.76v27.902h-123.76zM610.066 510.959h123.76v27.902h-123.76zM665.936 689.082h62.532v27.902h-62.532zM633.087 194.47l16.4 11.448-80.858 115.834-16.4-11.448zM725.26 204.73l13.734 14.54L634.785 317.7l-13.733-14.539zM800.489 240.542l13.142 15.076-52.696 45.935-13.142-15.076zM468.638 187.397l27.52 56.292-17.968 8.784-27.52-56.292zM331.501 204.24l120.28 98.428-12.666 15.478-120.28-98.427zM243.412 238.813l42.402 17.49-7.626 18.488-42.402-17.49z"
                          fill="#300604"
                          p-id="3037"
                        ></path>
                      </svg>
                    </span>
                  </span>

                  <span class="descColor"> 单位阅读 </span>
                  <span class="numColor">
                    {{ detailInfo["count"] }}
                  </span>
                </div>

                <div class="box-item commonBorder">
                  <span>
                    <span>
                      <svg
                        t="1647759201115"
                        class="icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        p-id="30404"
                        width="32"
                        height="32"
                      >
                        <path
                          d="M180.2 363.5h133.1c15.6 0 30.8-4.9 43.5-14l29.1-20.9c66.1-47.5 106.9-122.5 110.9-203.8C497.9 102 516.7 84 539.6 84h4.8c20.7 0 35.2 7 51 28.1 48.4 60.4 50.3 140.4 35.9 214.4l-2.3 12c-4.9 11.9 3.9 24.9 16.7 24.9H828c45.1 0 78.4 8.1 67.2 86.2C873.5 639 818.3 815.7 797 857.4c-12.8 23.7-31.6 39.3-56.6 39.3H209.7c-28.1 0-51.3-21.7-53.3-49.7L127 420.4c-2.2-30.7 22.2-56.9 53.2-56.9z"
                          fill="#FFA820"
                          p-id="30405"
                        ></path>
                        <path
                          d="M630.8 896.9h109.5c25 0 43.8-15.7 56.6-39.3 21.2-41.8 76.5-218.5 98.2-407.8 10.3-71.3-16.6-84.2-55.7-85.9 21.5 49.5 33.4 104.1 33.4 161.6-0.1 165.7-99.5 308.3-242 371.4zM151.8 781.8l4.5 65.4c1.9 28 25.2 49.7 53.3 49.7h93c-59-26.1-110.6-65.8-150.8-115.1zM497.2 120.4c42.4 3.2 83 12.8 120.7 28-6-12.6-13.5-24.8-22.7-36.2-7.8-10.4-15.3-17.4-23.4-21.8-12.8-2.5-25.9-4.4-39-5.8-18.2 2.9-32.8 17.3-35.6 35.8z"
                          fill="#FEB133"
                          p-id="30406"
                        ></path>
                        <path
                          d="M449.1 856.1c-130.5 0-244.2-71.4-304.4-177.3l7.1 103c40.2 49.3 91.8 89 150.7 115.1h328.3c142.5-63 242-205.7 242-371.5 0-57.4-11.9-112-33.4-161.6-3.7-0.2-7.6-0.2-11.5-0.2h-59.2c19.5 43.6 30.3 91.9 30.3 142.7-0.1 193.1-156.7 349.8-349.9 349.8zM496.7 124.8c-0.6 11.6-1.9 23-3.9 34.2 52.3 6.5 101 24.6 143.4 51.5-3.1-21.6-8.9-42.6-18.3-62.3-37.8-15.2-78.3-24.8-120.7-28-0.2 1.6-0.4 3.1-0.5 4.6z"
                          fill="#FEB533"
                          p-id="30407"
                        ></path>
                        <path
                          d="M482.8 198.1c60 10.6 113.6 39.4 155.1 80.4 1.8-22.8 1.6-45.7-1.6-67.9-42.5-27-91.2-45-143.4-51.5-2.5 13.3-5.8 26.3-10.1 39zM431.4 780.6c-162.1 0-293.5-131.4-293.5-293.5 0-43.2 9.3-84.2 26.1-121.1-22.8 7.2-38.8 29.3-37.1 54.5l17.8 258.2C204.8 784.6 318.6 856 449.1 856c193.2 0 349.8-156.6 349.8-349.8 0-50.8-10.8-99.1-30.3-142.7h-70.9c17.5 37.6 27.2 79.4 27.2 123.6 0 162.1-131.4 293.5-293.5 293.5z"
                          fill="#FEBA33"
                          p-id="30408"
                        ></path>
                        <path
                          d="M466.1 236.6C572 260.5 651 355 651 468.1c0 131-106.2 237.3-237.3 237.3S176.5 599.1 176.5 468.1c0-37.5 8.7-73 24.2-104.5h-20.6c-5.6 0-11.1 0.9-16.2 2.5-16.8 36.9-26.1 77.9-26.1 121.1 0 162.1 131.4 293.5 293.5 293.5s293.5-131.4 293.5-293.5c0-44.2-9.8-86-27.2-123.6h-52.1c-12.9 0-21.6-13-16.7-24.9l2.3-12c3.1-15.8 5.4-31.9 6.7-48.1-41.5-41.1-95.2-69.8-155.1-80.4-4.5 13.2-10 26-16.6 38.4z"
                          fill="#FFBE34"
                          p-id="30409"
                        ></path>
                        <path
                          d="M413.7 705.3c131 0 237.3-106.2 237.3-237.3 0-113-79.1-207.6-184.9-231.4-6.9 13.1-14.9 25.6-23.9 37.4 77.6 20.4 134.9 91 134.9 175 0 99.9-81 180.9-180.9 180.9s-180.9-81-180.9-180.9c0-30.9 7.8-60 21.4-85.4h-36c-15.5 31.5-24.2 67-24.2 104.5 0 131 106.2 237.2 237.2 237.2z"
                          fill="#FFC234"
                          p-id="30410"
                        ></path>
                        <path
                          d="M396.2 629.9c99.9 0 180.9-81 180.9-180.9 0-84-57.3-154.6-134.9-175-9.6 12.6-20.4 24.4-32.3 35.2 53.7 13.9 93.3 62.7 93.3 120.7 0 68.9-55.8 124.7-124.7 124.7s-124.7-55.8-124.7-124.7c0-24.4 7-47.1 19.1-66.4h-36.3C223 389 215.3 418.1 215.3 449c0 99.9 81 180.9 180.9 180.9z"
                          fill="#FFC734"
                          p-id="30411"
                        ></path>
                        <path
                          d="M378.5 554.5c68.9 0 124.7-55.8 124.7-124.7 0-58-39.6-106.8-93.3-120.7-7.6 6.9-15.6 13.4-24 19.5l-29.1 20.9c-12.7 9.1-27.9 14-43.5 14H273c-12.1 19.2-19.1 42-19.1 66.4-0.1 68.8 55.8 124.6 124.6 124.6z"
                          fill="#FFCB34"
                          p-id="30412"
                        ></path>
                        <path
                          d="M740.3 905.9H209.6c-32.7 0-60-25.5-62.2-58.1L118 421.2c-1.2-17.2 4.9-34.3 16.6-46.8 11.8-12.6 28.4-19.8 45.6-19.8h133.1c13.8 0 27.1-4.3 38.3-12.3l29.1-20.9c31.3-22.5 57.7-52.3 76.1-86.2 18.4-33.9 29.2-72.2 31-110.7 1.3-27.7 24.1-49.4 51.8-49.4h4.8c24.4 0 41.3 9.2 58.1 31.6 43.1 53.9 55.8 128.5 37.6 221.7l-2.5 12.8-0.3 0.8c-1.2 2.8-0.9 5.9 0.9 8.5 1.7 2.6 4.5 4 7.5 4H828c22.8 0 48.5 1.5 64.2 19.7 13.4 15.5 17.2 39.8 11.9 76.6-12.1 105.5-33.6 199.8-49.5 260.4-19.8 75.4-39.2 129.9-49.6 150.4l-0.1 0.2c-15.7 28.8-38 44.1-64.6 44.1zM180.2 372.5c-12.4 0-24 5-32.4 14.1s-12.7 20.9-11.8 33.3l29.4 426.6c1.6 23.2 21 41.3 44.3 41.3h530.7c19.9 0 36.2-11.6 48.6-34.5 10-19.7 28.8-72.7 48.2-146.7 15.8-60 37.1-153.5 49-258v-0.3c4.5-30.9 1.9-51.3-7.7-62.4-9.7-11.2-27.2-13.5-50.6-13.5H645.5c-9 0-17.4-4.5-22.5-12-4.9-7.2-5.9-16.3-2.9-24.4l2.2-11.2c17.1-87.9 5.6-157.5-34.1-207.1l-0.2-0.2C574.5 99.4 563 93 544.2 93h-4.8c-18.1 0-32.9 14.2-33.8 32.2-2 41.2-13.5 82.2-33.2 118.5-19.7 36.3-47.9 68.2-81.4 92.2l-29 20.9c-14.3 10.3-31.2 15.7-48.8 15.7h-133z"
                          fill="#FFA820"
                          p-id="30413"
                        ></path>
                        <path
                          d="M536.9 198.5c-9.7-2.4-19.4 3.5-21.8 13.2-15.5 63-62.2 107.6-62.8 108.1-7.2 6.8-7.6 18.2-0.8 25.4 3.5 3.8 8.3 5.7 13.1 5.7 4.4 0 8.8-1.6 12.3-4.9 2.2-2.1 55-52.1 73.1-125.8 2.5-9.6-3.4-19.3-13.1-21.7zM411.9 354.5l-9.5 6.8c-8.1 5.8-9.9 17.1-4.1 25.1 3.2 4.5 8.1 7 13.2 7.4 4.1 0.3 8.3-0.8 11.9-3.4l9.5-6.8c8.1-5.8 9.9-17.1 4.1-25.1-5.8-8-17-9.8-25.1-4z"
                          fill="#FFFFFF"
                          p-id="30414"
                        ></path>
                        <path
                          d="M222 426.3h78.3c13.4 0 24.3 10.9 24.3 24.3v359.6c0 13.4-10.9 24.3-24.3 24.3h-54.5c-12.8 0-23.4-9.9-24.2-22.7l-23.8-359.6c-0.9-14 10.2-25.9 24.2-25.9z"
                          fill="#FFE3B4"
                          p-id="30415"
                        ></path>
                      </svg>
                    </span>
                  </span>
                  <span class="descColor"> 点赞 </span>

                  <span class="numColor">
                    {{ detailInfo["like"] }}
                  </span>
                </div>

                <div class="box-item commonBorder">
                  <span>
                    <svg
                      t="1648031415437"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      p-id="5560"
                      width="32"
                      height="32"
                    >
                      <path
                        d="M902.4 291.2L692.2 128.7c-44-34-107.9-2.6-107.9 53v45c-188.9 1.3-341.9 159.8-341.9 356 0 42.7 7.1 80.7 19 118.7C308.8 563.7 434.7 464 584.2 464v42.7c0 55.6 63.9 87 107.9 53l210.3-162.5c34.7-26.8 34.7-79.2 0-106z"
                        fill="#2867CE"
                        p-id="5561"
                      ></path>
                      <path
                        d="M768.3 901.9H257.7c-93.1 0-168.5-75.5-168.5-168.5V353.6c0-93.1 75.5-168.5 168.5-168.5h49.6c26.6 0 48.1 21.5 48.1 48.1s-21.5 48.1-48.1 48.1h-49.6c-40 0-72.4 32.4-72.4 72.4v379.8c0 40 32.4 72.4 72.4 72.4h510.5c40 0 72.4-32.4 72.4-72.4v-132c0-26.6 21.5-48.1 48.1-48.1s48.1 21.5 48.1 48.1v132c0 93-75.5 168.4-168.5 168.4z"
                        fill="#BDD2EF"
                        p-id="5562"
                      ></path>
                    </svg>
                  </span>

                  <span class="descColor"> 分享 </span>

                  <span class="numColor">
                    {{ detailInfo["share"] }}
                  </span>

                  <span> </span>
                </div>
                <div class="box-item commonBorder">
                  <span>
                    <svg
                      t="1648029984898"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      p-id="16410"
                      width="32"
                      height="32"
                    >
                      <path
                        d="M237.056 144.1792h314.9312c74.8544 0 135.5264 60.672 135.5264 135.5264v220.7744c0 74.8544-60.672 135.5264-135.5264 135.5264H323.3792l-117.504 96.3584c-12.032 9.8816-30.1568 1.3312-30.1568-14.2848v-96.768c-44.032-22.3744-74.24-68.096-74.24-120.8832V279.7056c0-74.8544 60.7232-135.5264 135.5776-135.5264z"
                        fill="#EF8F9F"
                        p-id="16411"
                      ></path>
                      <path
                        d="M516.7104 151.8592s80.2816 35.072 80.2816 85.9648c51.456 0 92.16-4.5056 96.6656 0 4.5568 4.4544-35.0208-99.584-176.9472-85.9648z"
                        fill="#E06C85"
                        p-id="16412"
                      ></path>
                      <path
                        d="M194.304 757.1456c-5.632 0-11.3664-1.2288-16.7424-3.7888a38.6048 38.6048 0 0 1-22.272-35.1744V633.344c-46.08-28.3136-74.24-78.1312-74.24-132.864V279.7056c0-86.016 69.9904-156.0064 156.0064-156.0064h314.9312c86.016 0 156.0064 69.9904 156.0064 156.0064v220.7744c0 86.016-69.9904 156.0064-156.0064 156.0064H330.7008l-111.8208 91.7504a38.62016 38.62016 0 0 1-24.576 8.9088zM237.056 164.6592c-63.4368 0-115.0464 51.6096-115.0464 115.0464v220.7744c0 43.52 24.1664 82.8928 63.0272 102.6048 6.8608 3.4816 11.2128 10.5472 11.2128 18.2784v92.4672l114.176-93.6448c3.6864-3.0208 8.2432-4.6592 13.0048-4.6592h228.608c63.4368 0 115.0464-51.6096 115.0464-115.0464V279.7056c0-63.4368-51.6096-115.0464-115.0464-115.0464H237.056z"
                        fill="#211D38"
                        p-id="16413"
                      ></path>
                      <path
                        d="M780.3904 240.8448H440.2688c-80.8448 0-146.3808 65.536-146.3808 146.3808v238.3872c0 80.8448 65.536 146.3808 146.3808 146.3808h246.8864l126.8736 104.0896c13.0048 10.6496 32.5632 1.4336 32.5632-15.4112v-104.4992c47.5648-24.1664 80.1792-73.5232 80.1792-130.56V387.2256c0-80.8448-65.536-146.3808-146.3808-146.3808z"
                        fill="#F3556E"
                        p-id="16414"
                      ></path>
                      <path
                        d="M763.8016 253.0304s101.7856 35.072 101.7856 130.048v200.1408c0 52.48-8.3968 106.752-61.3376 123.6992-12.4928 3.9936-21.1968 15.3088-21.1968 28.416v37.888l-70.3488-54.784a29.7472 29.7472 0 0 0-18.2272-6.2464H442.3168c-44.0832 0-125.9008-16.5888-139.1104-79.5136 0.768 39.936 23.7568 143.616 144.384 143.616h253.2864l124.7744 104.8064 14.336-2.6624 14.336-122.5216s73.1136-60.672 73.1136-138.7008V355.9936c0-33.1776-44.1344-119.1424-163.6352-102.9632z"
                        fill="#D6283F"
                        p-id="16415"
                      ></path>
                      <path
                        d="M826.5728 901.12c-9.1136 0-18.1248-3.1232-25.5488-9.216l-121.2416-99.4304H440.2688c-92.0064 0-166.8608-74.8544-166.8608-166.8608V387.2256c0-92.0064 74.8544-166.8608 166.8608-166.8608h340.1216c92.0064 0 166.8608 74.8544 166.8608 166.8608v238.4384c0 58.8288-30.4128 112.384-80.1792 142.592v92.4672c0 15.7696-8.8576 29.7472-23.1424 36.5056-5.5296 2.6112-11.4688 3.8912-17.3568 3.8912zM440.2688 261.3248c-69.4272 0-125.9008 56.4736-125.9008 125.9008v238.4384c0 69.4272 56.4736 125.9008 125.9008 125.9008h246.8864c4.7104 0 9.3184 1.6384 13.0048 4.6592l125.952 103.3216V756.224c0-7.7312 4.352-14.7456 11.2128-18.2784 42.5472-21.6064 68.9664-64.6656 68.9664-112.2816V387.2256c0-69.4272-56.4736-125.9008-125.9008-125.9008H440.2688z"
                        fill="#211D38"
                        p-id="16416"
                      ></path>
                      <path
                        d="M477.952 507.7504m-37.6832 0a37.6832 37.6832 0 1 0 75.3664 0 37.6832 37.6832 0 1 0-75.3664 0Z"
                        fill="#FFFFFF"
                        p-id="16417"
                      ></path>
                      <path
                        d="M609.792 507.7504m-37.6832 0a37.6832 37.6832 0 1 0 75.3664 0 37.6832 37.6832 0 1 0-75.3664 0Z"
                        fill="#FFFFFF"
                        p-id="16418"
                      ></path>
                      <path
                        d="M743.8336 507.7504m-37.6832 0a37.6832 37.6832 0 1 0 75.3664 0 37.6832 37.6832 0 1 0-75.3664 0Z"
                        fill="#FFFFFF"
                        p-id="16419"
                      ></path>
                      <path
                        d="M328.0896 583.168a12.8 12.8 0 0 1-12.8-12.8V384c0-56.1152 37.888-104.96 92.16-118.8352 6.8096-1.792 13.824 2.3552 15.5648 9.216 1.7408 6.8608-2.3552 13.824-9.216 15.5648A96.9728 96.9728 0 0 0 340.8896 384v186.3168c0 7.1168-5.7344 12.8512-12.8 12.8512zM481.4848 286.976h-23.3472a12.8 12.8 0 0 1 0-25.6h23.3472a12.8 12.8 0 0 1 0 25.6zM134.5536 481.3824a12.8 12.8 0 0 1-12.8-12.8V284.3648c0-55.5008 37.3248-103.8848 90.7264-117.6064a12.8 12.8 0 0 1 6.3488 24.7808c-42.0864 10.8544-71.4752 48.9984-71.4752 92.8256v184.2176c0 7.0656-5.6832 12.8-12.8 12.8zM285.2864 188.5696h-22.9376a12.8 12.8 0 0 1 0-25.6h22.9376a12.8 12.8 0 0 1 0 25.6z"
                        fill="#FFFFFF"
                        p-id="16420"
                      ></path>
                      <path
                        d="M230.6048 926.8224c-38.5536 0-69.9392-31.3856-69.9392-69.9392s31.3856-69.9392 69.9392-69.9392 69.9392 31.3856 69.9392 69.9392-31.3856 69.9392-69.9392 69.9392z m0-104.0384c-18.7904 0-34.0992 15.3088-34.0992 34.0992s15.3088 34.0992 34.0992 34.0992 34.0992-15.3088 34.0992-34.0992-15.3088-34.0992-34.0992-34.0992z"
                        fill="#E2E5F1"
                        p-id="16421"
                      ></path>
                      <path
                        d="M830.3104 157.696m-34.6624 0a34.6624 34.6624 0 1 0 69.3248 0 34.6624 34.6624 0 1 0-69.3248 0Z"
                        fill="#E2E5F1"
                        p-id="16422"
                      ></path>
                      <path
                        d="M725.2992 892.6208H629.248c-8.4992 0-15.36-6.8608-15.36-15.36s6.8608-15.36 15.36-15.36h96.0512c8.4992 0 15.36 6.8608 15.36 15.36s-6.8608 15.36-15.36 15.36z"
                        fill="#E2E5F1"
                        p-id="16423"
                      ></path>
                      <path
                        d="M579.6352 878.3872m-17.3568 0a17.3568 17.3568 0 1 0 34.7136 0 17.3568 17.3568 0 1 0-34.7136 0Z"
                        fill="#E2E5F1"
                        p-id="16424"
                      ></path>
                    </svg>
                  </span>
                  <span class="descColor"> 评论 </span>
                  <span class="numColor">
                    {{ detailInfo["comment"] }}
                  </span>
                </div>
              </div>
            </div>
          </TabPane>
        </Tabs> -->
      </div>

      <div slot="footer">
        <Button size="large" long @click="showDetailStatus = false"
          >确定</Button
        >
      </div>
    </Modal>

    <dept-choose-com
      :defaultEndDate="endDate"
      :defaultStartDate="startDate"
      :showModal="showModal"
      @chooseDept="chooseDept"
      :isShowDept="isShowDept"
      @cancel="showModal = false"
      :isShowTime="true"
    ></dept-choose-com>

    <task-load-com
      :postData="postData"
      :postUrl="postUrl"
      @initData="initData"
      :showTaskLoad="showTaskLoad"
      v-if="showTaskLoad"
    ></task-load-com>

    <dia-logliu :show="dailogshow" :options="dailogoptions"></dia-logliu>
    <loading-cmp v-if="showLoad"></loading-cmp>

    <div v-show="showList && isUserPm">
      <desc-com
        :title="descTitle"
        :startDate="startDate"
        :endDate="endDate"
        :isFilter="true"
        :sortList="sortList"
        @sortData="sortData"
      ></desc-com>

      <div
        style="
          margin-top: -4px;
          text-align: left;
          color: #999;
          padding: 0 10px;
          font-size: 10px;
          display: flex;
          line-height: 20px;
          justify-content: space-between;
        "
      >
        <div>平台总活跃度 = 阅读+点赞+评论+分享</div>
        <div>
          <Button size="small" @click="toUrl('/userDegree')">用户排名</Button>
          <!-- <Button size="small" @click="showJituanDetailStatus = true"
            >集团趋势</Button
          > -->
        </div>
      </div>

      <div style="margin-top: 30px">
        <list-com
          @chooseListItem="chooseListItem"
          :columns="columns"
          :sortList="sortList"
          :title="title"
          :showList="dataList"
          :huizongList="[huizongData]"
          :showLoad="showLoad"
        ></list-com>
      </div>
    </div>

    <no-pass-com v-show="!isUserPm"> </no-pass-com>
  </div>
</template>

<script>
// @ is an alias to /src
import _ from "lodash";
import moment from "moment";
import {
  statsPost,
  getUserPm,
  getRadio,
  getOptBar,
  getRadioDate,
  getYearOnYearBasisDate,
} from "../../api/httpApi";
import commonTop from "../../components/commonTop.vue";
import pieChart from "../../components/pieChart.vue";
import loadingCmp from "../../components/loadingCmp.vue";
import diaLogliu from "../../components/dialogLiu.vue";
import listCom from "../../components/listCom.vue";
import deptChooseCom from "../../components/deptLevelChooseCom.vue";
import descCom from "../../components/descComAndSort.vue";
import noPassCom from "../../components/noPassCom.vue";
import huanBiTongBiCom from "../../components/huanBiTongBiCom.vue";
import taskLoadCom from "../../components/taskLoadCom.vue";
export default {
  name: "deptDegreeNew",
  components: {
    pieChart,
    loadingCmp,
    commonTop,
    diaLogliu,
    listCom,
    deptChooseCom,
    descCom,
    noPassCom,
    huanBiTongBiCom,
    taskLoadCom,
  },

  data() {
    return {
      huizongData: {}, // 汇总数据
      postData: {},
      postUrl: "/deptTj/tjReadInfo",
      showTaskLoad: false,

      optionHuanBiRadio: {},
      optionTongBiRadio: {},
      showJituanDetailStatus: false,

      filterNameArr: [],
      gethourly_date: "",
      drawerMoadal: false,
      showHuor: false,
      xAxis: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23,
      ],
      series: [],
      data_24h: [],
      dataDaily: [],

      descTitle: "", // 描述说明
      isShowSearch: false, // 是否显示搜索框
      isShowDept: false, // 超管部门选择
      isUserPm: true, // 是否有权限

      showModal: false, // 显示部门弹框
      deptIdArr: [], // 选择部门
      deptLevel: 2, // 部门层级
      startDate: "2022-01-01",
      endDate: "2022-02-20",

      showDetailStatus: false,

      detailInfo: {}, // 单位详情
      title: "单位",
      // columns: ["平台总活跃度", "人均活跃度", "阅读活跃度", "环比增长"],
      columns: [
        {
          name: "总活跃度",
          value: "平台总活跃度",
        },

        "人均活跃度",
        "环比增长",
        // "同比增长",
      ],

      sortList: [
        // 默认降序 desc  可选升序 asc
        { name: "平台总活跃度", value: "平台总活跃度", order: "desc" },
        { name: "人均活跃度", value: "averageActive", order: "desc" },
        // { name: "环比增长", value: "环比", order: "desc" },
      ],
      showList: true,
      deptData: [],
      dataList: [],
      yearOnYearBasisData: [],
      ratioData: [],

      showLoad: false,
      deptType: "单位",
      deptUserOption: {},
      dataNumOption: {},

      deptInfo: {},
      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项

      dataTimer: null,
      radioTimer: null,
      yearOnYearBasisTimer: null,
    };
  },
  methods: {
    // 数据任务组件
    initData(data) {
      this.showTaskLoad = false; // 销毁组件，为下次再次查询

      if (!_.isUndefined(data)) {
        const deptData = data.ret ? data.ret : [];
        this.dealData(deptData);
        // 增加环比同比
        this.getRadioAndYearOnYearData(this.postData);
      } else {
        this.dealData([]);
      }
    },
    // 数据任务组件初始化
    initTaskCom() {
      let _query = {
        startDate: this.startDate,
        endDate: this.endDate,
      };
      if (this.deptIdArr.length > 0) {
        _query["deptIdArr"] = this.deptIdArr;
        _query["tjLevel"] = this.deptLevel;
      }
      this.postData = _query;

      this.showTaskLoad = true;
    },

    toUrl(url) {
      this.$router.push({ path: url });
    },

    // 选择部门
    chooseDept(emitData) {
      this.tjLevel = emitData.level;
      this.deptIdArr = emitData.deptIdArr;
      this.startDate = emitData.startDate;
      this.endDate = emitData.endDate;

      this.showModal = false;
      this.initTaskCom();
    },
    clearTimmerFun() {
      if (this.dataTimer) {
        clearTimeout(this.dataTimer);
      }
      if (this.radioTimer) {
        clearTimeout(this.radioTimer);
      }
      if (this.yearOnYearBasisTimer) {
        clearTimeout(this.yearOnYearBasisTimer);
      }
    },

    // 处理数据
    dealData(data) {
      let dataList = [];

      this.huizongData = {
        deptName: "汇总",
        平台总活跃度: 0,
        人均活跃度: 0,
        环比增长: 0,
        averageActive: 0,
        userStatusCount: 0,
      };

      for (const iterator of data) {
        // comment: 100
        // count: 2352
        // deptName: "广州客运段"
        // like: 3388
        // share: 23
        let allNum =
          iterator["comment"] +
          iterator["count"] +
          iterator["like"] +
          iterator["share"];

        let averageActive = 0;
        if (iterator.userStatusCount > 0) {
          averageActive = allNum / iterator.userStatusCount;
        }

        dataList.push(
          Object.assign(iterator, {
            平台总活跃度: allNum,
            averageActive,
            人均活跃度: averageActive.toFixed(2),
            环比增长: "-",
            同比增长: "-",
            本期数: averageActive,
          })
        );
        this.huizongData["平台总活跃度"] += allNum;
        this.huizongData["userStatusCount"] += iterator["userStatusCount"];
      }
      this.dataList = _.reverse(
        _.sortBy(dataList, ["averageActive", "平台总活跃度"])
      );

      // 平台人均积分
      let averageActive = 0;
      if (this.huizongData["userStatusCount"]) {
        averageActive =
          this.huizongData["平台总活跃度"] /
          this.huizongData["userStatusCount"];
      }
      this.huizongData["人均活跃度"] = averageActive.toFixed(2);
      this.huizongData["averageActive"] = averageActive;
    },

    // 显示详情
    chooseListItem(item) {
      // console.log("item--------", item);
      if ("汇总" == item.deptName) {
        return;
      }
      this.deptUserOption = null;
      this.detailInfo = item;

      this.showDetailStatus = true;
    },
    chooseItem(data) {
      this.showTaskList = false;
      this.deptData = data.data.retData;
    },

    // 数据排序
    sortData(data) {
      if (data && data.value) {
        if (data.order == "aes") {
          // 降序
          this.dataList = _.sortBy(this.dataList, [data.value]);
        } else {
          // 升序
          this.dataList = _.reverse(_.sortBy(this.dataList, [data.value]));
        }

        this.$nextTick(() => {
          this.$forceUpdate();
        });
      }
    },

    /******************** 环比/同比 */
    async commonRatioDataFun(query, huanbiType, type, typeVal) {
      this.clearTimmerFun();
      try {
        let _query = query;
        //  计算周期
        let { startDate, endDate } = getRadioDate(this.startDate, this.endDate);
        if (huanbiType == "tongbi") {
          const dateObj = getYearOnYearBasisDate(this.startDate, this.endDate);
          startDate = dateObj.startDate;
          endDate = dateObj.endDate;
        }

        _query["startDate"] = startDate;
        _query["endDate"] = endDate;
        console.log('aaqq',_query);
        const ret = await statsPost(this.postUrl, _query);
        console.log('zzzzzlzlzl',ret);
        if (ret.status) {
          this.radioTimer = setTimeout(async () => {
            await this.commonRatioDataFun(query, huanbiType, type, typeVal);
          }, 4000);
        } else {
          const data = _.get(ret, ["ret"], []);
          this.dealCommonRadioData(data, type, typeVal);
        }
      } catch (error) {
        this.clearTimmerFun();
      }
    },

    dealCommonRadioData(data, type, typeVal) {
      let activeAll = 0;
      for (const iterator of this.dataList) {
        // 计算环比差
        const _huanBiData = _.find(data, (o) => {
          return o.deptId == iterator.deptId;
        });

        if (_huanBiData) {
          let allNum =
            _huanBiData["comment"] +
            _huanBiData["count"] +
            _huanBiData["like"] +
            _huanBiData["share"];

          let averageActive = 0;
          if (iterator.userStatusCount > 0) {
            averageActive = allNum / iterator.userStatusCount;
          }
          activeAll += allNum;

          // 环比增长率=（本期数-上期数）/上期数×100%
          iterator[type] = getRadio(iterator["averageActive"], averageActive);

          iterator[type.slice(0, 2)] = parseFloat(iterator[type]);
          iterator[typeVal] = averageActive;
        }
      }

      let averageActive = 0;
      if (this.huizongData["userStatusCount"]) {
        averageActive = activeAll / this.huizongData["userStatusCount"];
      }

      this.huizongData["环比增长"] = getRadio(
        this.huizongData["averageActive"],
        averageActive
      );

      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },

    async getRadioAndYearOnYearData(query) {
      try {
        this.$Message.info("环比数据查询中");

        await this.commonRatioDataFun(query, "huanbi", "环比增长", "上期数");
      } catch (error) {
        console.log("------", error);
      }
    },
  },
  destroyed() {
    this.clearTimmerFun();
  },
  async created() {
    this.startDate = moment().subtract(1, "weeks").format("YYYY-MM-DD");
    this.endDate = moment().subtract(1, "days").format("YYYY-MM-DD");

    const userPm = getUserPm();
    if (userPm && userPm.statsPmStatus) {
      this.tjLevel = userPm.datalevel;
      this.deptIdArr = JSON.parse(localStorage.getItem("pmDeptIdArr"));

      this.isShowSearch = true;
      this.isShowDept = true;

      this.initTaskCom();
    } else {
      this.isUserPm = false;
    }
  },
  computed: {},
};
</script>

<style scoped>
.deptMainTop {
  text-align: center;
  /* color: white; */
  padding: 15px 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
  grid-template-rows: repeat(1, 1fr);
  text-align: center;
  align-items: center;
  justify-content: center;
}

.deptMainTop .box-item {
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  font-size: 11px;
  position: relative;
  font-weight: bold;
}
.deptMainTop .box-item span {
  flex: 1;
  color: #b57464;
  font-size: 16px;
}

.deptMainTop .box-item .numColr,
.numColor {
  font-size: 16px;
  font-weight: bold;
  color: #866060;
}
.deptMainTop .box-item .descColor,
.descColor {
  color: #bbb;
  overflow: hidden;
  font-size: 10px;
}
</style>
